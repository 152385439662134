export enum EnumEmployeeRole {
  BASIC = "BASIC",
  HR = "HR",
}

export enum HR {
  "HR" = "HR",
  "ACCOUNTING" = "ACCOUNTING",
  "HR_DIRECTOR" = "HR_DIRECTOR",
}
