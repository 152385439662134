/* eslint-disable no-undef */

export const env = getEnvConfig()

export const theme = getThemeConfig()

const childbirthAssistanceFunctions = getChildbirthAssistanceFunctionsConfig()
const dentalTreatmentFunctions = getDentalTreatmentFunctionsConfig()
const diseaseProtectionVaccineFunctions = getDiseaseProtectionVaccineFunctionsConfig()
const workEquipmentFunctions = getWorkEquipmentFunctionsConfig()
const equipmentForOnlineMeetingsFunctions = getEquipmentForOnlineMeetingsFunctionsConfig()
const healthCheckupFunctions = getHealthCheckupFunctionsConfig()
const healthInsuranceFunctions = getHealthInsuranceFunctionsConfig()
const physicalHealthFunctions = getPhysicalHealthFunctionsConfig()
const marriageAssistanceFunctions = getMarriageAssistanceFunctionsConfig()
const medicalExpensesFunctions = getMedicalExpensesFunctionsConfig()
const medicalTreatmentAndRehabilitationAlternativeMedicineFunctions = getMedicalTreatmentAndRehabilitationAlternativeMedicineFunctionsConfig()
const opdIpdExcessFunctions = getOpdIpdExcessFunctionsConfig()
const religiousCeremonyAssistanceFunctions = getReligiousCeremonyAssistanceFunctionsConfig()
const skillsDevelopmentFunctions = getSkillsDevelopmentFunctionsConfig()
const sportsEquipmentFunctions = getSportsEquipmentFunctionsConfig()
const strengtheningProgramsForBodyFunctions = getStrengtheningProgramsForBodyFunctionsConfig()
const visualAidsFunctions = getVisualAidsFunctionsConfig()

const budgetFunction = getBudgetFunctionConfig()
const requestFunctions = getRequestNameFunctionConfig()

export const functions = {
  autoCal: {
    ...budgetFunction.autoCal,
    ...childbirthAssistanceFunctions.autoCal,
    ...dentalTreatmentFunctions.autoCal,
    ...diseaseProtectionVaccineFunctions.autoCal,
    ...workEquipmentFunctions.autoCal,
    ...equipmentForOnlineMeetingsFunctions.autoCal,
    ...healthCheckupFunctions.autoCal,
    ...healthInsuranceFunctions.autoCal,
    ...physicalHealthFunctions.autoCal,
    ...marriageAssistanceFunctions.autoCal,
    ...medicalExpensesFunctions.autoCal,
    ...medicalTreatmentAndRehabilitationAlternativeMedicineFunctions.autoCal,
    ...opdIpdExcessFunctions.autoCal,
    ...religiousCeremonyAssistanceFunctions.autoCal,
    ...skillsDevelopmentFunctions.autoCal,
    ...sportsEquipmentFunctions.autoCal,
    ...strengtheningProgramsForBodyFunctions.autoCal,
    ...visualAidsFunctions.autoCal,
  },
  validate: {
    ...childbirthAssistanceFunctions.validate,
    ...dentalTreatmentFunctions.validate,
    ...diseaseProtectionVaccineFunctions.validate,
    ...workEquipmentFunctions.validate,
    ...equipmentForOnlineMeetingsFunctions.validate,
    ...healthCheckupFunctions.validate,
    ...healthInsuranceFunctions.validate,
    ...physicalHealthFunctions.validate,
    ...marriageAssistanceFunctions.validate,
    ...medicalExpensesFunctions.validate,
    ...medicalTreatmentAndRehabilitationAlternativeMedicineFunctions.validate,
    ...opdIpdExcessFunctions.validate,
    ...religiousCeremonyAssistanceFunctions.validate,
    ...requestFunctions.validate,
    ...skillsDevelopmentFunctions.validate,
    ...sportsEquipmentFunctions.validate,
    ...strengtheningProgramsForBodyFunctions.validate,
    ...visualAidsFunctions.validate,
  },
  calculate: {
    ...requestFunctions.calculate,
  },
  custom: {
    ...requestFunctions.custom,
  }
}

const configs = {
  env,
  theme,
  functions,
}

export default configs
