import Box from "@material-ui/core/Box"
import { Divider, Grid, Icon, Typography } from "components"
import { IconName } from "components/common/Icon"
import { env } from "configs"
import { useMemo } from "react"
import styled from "styled-components"
import { AppColor } from "theme/app-color"
import { toCurrency } from "utils/helper"

const Categories = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  height: fit-content;
  min-width: fit-content;
  max-width: fit-content;
  padding: 2px;
  margin-left: 8px;
`

const CategoriesComponent = (props: { type: string; chipBackgroundColor: string; chipTitleColor: string }) => {
  const { type, chipBackgroundColor, chipTitleColor } = props

  // if (env.USER_CAN_SEE_REMAINING_BUDGET) {
  //   return (
  //     <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", gap: "4px" }}>
  //       <Categories color={chipBackgroundColor}>
  //         <Typography variant="caption" color={chipTitleColor}>
  //           {type}
  //         </Typography>
  //       </Categories>
  //       <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
  //         <Icon name="point" style={{ width: "10px", height: "10px" }} />
  //         <Typography variant="subtitle1">ตามจำนวนที่จ่าย</Typography>
  //       </div>
  //     </div>
  //   )
  // } else {
    return (
      <Box display="flex" alignItems="center">
        <Categories color={chipBackgroundColor}>
          <Typography variant="caption" color={chipTitleColor}>
            {type}
          </Typography>
        </Categories>
      </Box>
    )
  // }
}

const FooterContainer = styled.div`
  bottom: 4px;
  padding: 8px 0px 0px 0px;
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: auto;
  border-top: 1px solid ${AppColor["Gray/Flat Button Bg"]};
`

const ContainerFlex = styled.div<{ js: string; row?: boolean; al: string }>`
  display: flex;
  justify-content: ${(props) => props.js};
  align-items: ${(props) => props.al};
  flex-direction: ${(props) => (props.row ? "row" : "column")};
`

interface FooterProps {
  totalAmount: number
  approvedRequest: number
}

const Footer = (props: FooterProps) => {
  const { totalAmount, approvedRequest } = props
  return (
    <FooterContainer>
      <Grid item xs={12} sm={12}>
        <ContainerFlex js="space-between" row al="">
          <ContainerFlex js="" al="flex-start">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Gray/Flat Button Bg">
                จำนวนครั้งที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(approvedRequest, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
          <ContainerFlex js="" al="flex-end">
            <Box mb="8px">
              <Typography variant="subtitle1" color="Text/Gray Success">
                จำนวนเงินที่เบิก
              </Typography>
            </Box>
            <Typography variant="body2" color="Text/Black2">
              {toCurrency(totalAmount, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
            </Typography>
          </ContainerFlex>
        </ContainerFlex>
      </Grid>
    </FooterContainer>
  )
}

const FooterList = (props: any) => {
  const { budget, type, yearlyBudget } = props

  const remainingBudget = type === "dental_fee" ? yearlyBudget.dentalBudget : yearlyBudget.budget
  const limitBudget = type === "dental_fee" ? yearlyBudget.companyDentalBudget : yearlyBudget.companyBudget
  return (
    <>
      {budget && (
        <>
          <Divider style={{ margin: "12px 0px 8px 0px", background: AppColor["Text/Gray"] }} />
          {budget.map((detail: any, index: any) => {
            const uniqueKey = `${detail.label}-${index}`
            return (
              <Box display="flex" flexDirection="column" alignItems="flex-start" pt={1} key={uniqueKey}>
                <Typography variant="body1" color="Text/Gray Success" style={{ fontSize: "12px", lineHeight: "16px" }}>
                  {detail.label}
                </Typography>
                <Box display="flex">
                  {yearlyBudget && detail.field && remainingBudget !== undefined ? (
                    <>
                      <Typography variant="body1" color="Text/Black2" style={{ fontSize: "12px", lineHeight: "16px" }}>
                        {toCurrency(remainingBudget, {
                          maximumFractionDigits: 0,
                          minimumFractionDigits: 0,
                        })}
                      </Typography>
                      {/* 
                      <Typography
                        variant="body1"
                        color="Text/Gray Preview"
                        style={{ fontSize: "12px", lineHeight: "16px" }}
                      >
                        /{toCurrency(limitBudget, { maximumFractionDigits: 0, minimumFractionDigits: 0 })}
                      </Typography> */}
                    </>
                  ) : (
                    <Typography
                      variant="body1"
                      color="Text/Black2"
                      style={{ fontSize: "12px", lineHeight: "16px", textAlign: "left" }}
                    >
                      {detail.value}
                    </Typography>
                  )}
                </Box>
              </Box>
            )
          })}
        </>
      )}
    </>
  )
}

const ClaimCardClickContainer = styled(Box)<{ ["display-only"]: boolean }>`
  &:hover {
    box-shadow: ${(props) => (props["display-only"] ? "none" : "-1px 0px 7px 4px rgba(142, 201, 231, 0.75)")};
    cursor: ${(props) => (props["display-only"] ? "context-menu" : "pointer")};
  }
`

export interface ClaimCardProps {
  type: string
  name?: string
  title: string
  iconColor: string
  chipTitleColor: string
  chipBackgroundColor: string

  displayOnly?: boolean
  fullSize?: boolean

  icon: IconName
  onClick?: (request?: any) => void

  totalAmount: number
  approvedRequest: number
  budget?: any
  budgetValues?: any
  yearlyBudget?: any
}

export const ClaimCard = (props: ClaimCardProps) => {
  const {
    totalAmount = 0,
    displayOnly = false,
    approvedRequest = 0,
    title,
    type,
    fullSize = false,
    icon,
    onClick,
    name,
    iconColor,
    chipTitleColor,
    chipBackgroundColor,
    budget,
    budgetValues = {},
    yearlyBudget,
  } = props

  const renderText = useMemo(() => {
    const textWithNewLine = title.split("\n")

    return textWithNewLine.map((line, index) => {
      const uniqueKey = `${line}-${index}`
      return (
        <Typography
          key={uniqueKey}
          title={line}
          variant="body1"
          color="Text/Black2"
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {line}
        </Typography>
      )
    })
  }, [title])

  const Content = (
    <ClaimCardClickContainer
      title={title}
      padding="8px"
      border={`1px solid ${AppColor["Gray/Flat Button Bg"]}`}
      borderRadius={4}
      bgcolor={AppColor["White / White"]}
      display-only={displayOnly}
    >
      {fullSize ? (
        <Box display="flex" flexDirection="column" padding="8px">
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center">
              <Box padding="4px" pr="8px">
                <Icon width="32px" height="32px" name={icon} color={iconColor} />
              </Box>
              <div>{renderText}</div>
            </Box>
            <CategoriesComponent
              type={type}
              chipBackgroundColor={chipBackgroundColor}
              chipTitleColor={chipTitleColor}
            />
          </Box>
          <Footer approvedRequest={approvedRequest} totalAmount={totalAmount} />
        </Box>
      ) : (
        <Box textAlign="center" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
          <Box padding="4px" pr="8px">
            <Icon width="32px" height="32px" name={icon} color={iconColor} />
          </Box>
          <Box padding="0px 16px">
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <div>{renderText}</div>
            </Box>

            <FooterList
              budgetValues={budgetValues}
              totalAmount={totalAmount}
              budget={budget}
              type={name}
              yearlyBudget={yearlyBudget}
            />
            {/* {env.USER_CAN_SEE_REMAINING_BUDGET && (
              <div style={{ display: "flex", alignItems: "center", gap: "4px", justifyContent: "center" }}>
                <Icon name="point" style={{ width: "10px", height: "10px" }} />
                <Typography variant="subtitle1">ตามที่จ่ายจริง</Typography>
              </div>
            )} */}
          </Box>
        </Box>
      )}
    </ClaimCardClickContainer>
  )

  return (
    <Grid item xs={fullSize ? 12 : 6} mb="8px">
      {displayOnly ? Content : <Box onClick={onClick}>{Content}</Box>}
    </Grid>
  )
}
