import { compose, withFormik, withHooks, withStores } from "enhancers"
import { Box, Button, DatePicker, Field, Form, Grid, Select, TextField, Typography } from "components"
import { gql } from "@apollo/client"
import { uniqBy } from "lodash"
import styled from "styled-components"
import { AppColor } from "theme/app-color"

const ResetFilterButton = styled(Box)`
  display: flex;
  align-items: center;
  text-decoration: underline;
  color: ${AppColor["Primary/Primary Text"]};
  :hover {
    cursor: pointer;
  }
`

const FilterClaimRequests = (props: any) => (
  <Box>
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Typography variant="h3">ตัวกรองเพิ่มเติม</Typography>
      <ResetFilterButton onClick={props.clearFilter}>
        <Typography variant="subtitle1">รีเซ็ตตัวกรอง</Typography>
      </ResetFilterButton>
    </Box>
    <Grid container spacing={0}>
      <Box mb={2}>
        <Typography variant="body1">ประเภทสวัสดิการ</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="benefitType"
          type="text"
          placeholder="ประเภทสวัสดิการ"
          options={props.benefitTypeOptions}
          fullWidth
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ชื่อสวัสดิการ</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="benefitTitle"
          type="text"
          placeholder="เลิอกชื่อสวัสดิการ"
          options={props.benefitTitleOptions}
          fullWidth
          disabled={!props.values.benefitType}
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ผู้ขอเบิก</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="requester"
          type="text"
          placeholder="ผู้ขอเบิก"
          options={props.requesterOptions}
          fullWidth
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ฝ่าย/สังกัด</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={Select}
          name="department"
          type="text"
          placeholder="ฝ่าย/สังกัด"
          options={props.department}
          fullWidth
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ตั้งแต่วันที่</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={DatePicker}
          name="startDate"
          placeholder="วันที่"
          fullWidth
          maxDate={props.values.endDate}
          onChange={(values: string) => props.setFieldValue("startDate", values)}
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">ถึงวันที่</Typography>
      </Box>
      <Grid item xs={12} mb={4}>
        <Field
          component={DatePicker}
          name="endDate"
          placeholder="วันที่"
          fullWidth
          minDate={props.values.startDate}
          onChange={(values: string) => props.setFieldValue("endDate", values)}
        />
      </Grid>
      <Box mb={2}>
        <Typography variant="body1">เลขอ้างอิง</Typography>
      </Box>
      <Grid item xs={12}>
        <Field component={TextField} name="referenceId" placeholder="เลขอ้างอิง" fullWidth />
      </Grid>
    </Grid>
  </Box>
)
const API = {
  GET_CLAIM_REQUEST_CONFIGS: gql`
    query GET_CLAIM_REQUEST_CONFIGS($employeeId: String!) {
      claimRequestConfigs(employeeId: $employeeId) {
        requests
      }
    }
  `,
  GET_DEPARTMENT_OPTION: gql`
    query GET_DEPARTMENT_OPTION {
      getDepartmentOption {
        label
        value
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo, employeeOptions, initialValues } = props
    const { useState, useQuery, useMemo, useEffect, useCallback, useFormikContext } = hooks

    const formikBag = useFormikContext()
    const { values, setValues, setFieldValue, resetForm } = formikBag

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const [benefitTypeOptions, setBenefitTypeOptions] = useState([])
    const [originalBenefitTitleOptions, setOriginalBenefitTitleOptions] = useState([])
    const [benefitTitleOptions, setBenefitTitleOptions] = useState([])
    const { data: department, loading, error } = useQuery(API.GET_DEPARTMENT_OPTION)

    useQuery(API.GET_CLAIM_REQUEST_CONFIGS, {
      variables: { employeeId: currentUser.id },
      onCompleted: (data: any) => {
        const current = data.claimRequestConfigs.requests

        const benefitType: any = []
        const benefitTitle: any = []

        current.forEach((item: any) => {
          benefitType.push({
            label: item.type,
            value: item.type,
          })

          benefitTitle.push({
            benefitType: item.type,
            label: item.title,
            value: item.title,
          })
        })

        setBenefitTypeOptions(uniqBy(benefitType, "label") as any)
        setOriginalBenefitTitleOptions(benefitTitle)
      },
      fetchPolicy: "network-only",
    })

    useEffect(() => {
      if (initialValues) {
        setValues(initialValues)
      }
    }, [initialValues, setValues])

    useEffect(() => {
      if (values.benefitType) {
        const result = originalBenefitTitleOptions.filter((item: any) => item.benefitType === values.benefitType)
        setBenefitTitleOptions(uniqBy(result, "label") as any)
      }

      if (values.benefitType === null) {
        setFieldValue("benefitTitle", null)
      }
    }, [values?.benefitType, originalBenefitTitleOptions, setFieldValue])

    const clearFilter = useCallback(async () => {
      setValues({
        benefitType: null,
        benefitTitle: null,
        requester: null,
        department: null,
        startDate: undefined,
        endDate: undefined,
        referenceId: "",
      })
    }, [setValues])

    const departmentOptions = useMemo(() => {
      if (loading || error) {
        return []
      }

      return department?.getDepartmentOption
    }, [loading, error, department])

    return {
      benefitTypeOptions,
      benefitTitleOptions,
      values,
      clearFilter,
      setFieldValue,
      department: departmentOptions,

      requesterOptions: employeeOptions,
    }
  }),
)

export default enhancer(FilterClaimRequests)
